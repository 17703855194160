<template>
  <ion-page id="characters-page" class="page scrollable bg-transparent">
    <h1 class="title">{{ pollData.name }}</h1>
    <p v-if="pollData && pollData.event_slug" class="mt-0">
      <router-link :to="{ name: 'event-details', params: { slug: pollData.event_slug } }">
        <span class="clickable-item-hov">{{ pollData.event_title }}</span>
      </router-link>
    </p>
    <div v-show="pollData.description" class="description p-3" v-html="pollData.description" />
    <div>
      <p v-if="pollData.starts_at">
        <strong v-show="dateHasPassed(pollData.starts_at)">Started</strong
        ><strong v-show="!dateHasPassed(pollData.starts_at)">Starts</strong> {{ formatTimeAgo(pollData.starts_at) }}
      </p>
      <p v-if="pollData.ends_at">
        <strong v-show="dateHasPassed(pollData.ends_at)">Ended</strong
        ><strong v-show="!dateHasPassed(pollData.ends_at)">Ends</strong> {{ formatTimeAgo(pollData.ends_at) }}
      </p>
    </div>
    <p class="q title">{{ pollData.question }}</p>
    <div class="w-100 container">
      <div class="row">
        <div
          v-for="(option, index) in pollOptions"
          class="clickable-item option-container col-6"
          :class="{ 'purple-bg': option.vote_count === maxVoteCount }"
          @click="makeSubmission(index)"
        >
          <img loading="lazy" :src="option.image" class="option-image" />
          <br />
          <span v-if="option" class="option-text">{{ option.text }}</span>
          <br />
          <span v-if="!isNaN(option.vote_count)" class="bold">Votes: {{ option.vote_count }}</span>
        </div>
      </div>
      <div v-if="pollSubmission && pollOptions && pollOptions[indexVotedFor]" class="py-3 center-text">
        <ion-badge class="mb-n1" color="tertiary" title="Done!"><i class="ti-check" /></ion-badge>
        You voted for: <strong>{{ pollOptions[indexVotedFor].text }}</strong>
      </div>
    </div>
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { toast } from '@/shared/native';
import { SiteEventTaskPoll, SiteEventTaskSubmission, SiteEventTaskPollOption } from '@/shared/types/static-types';
import { getEventTaskPoll, submitToEventTask, getUserTaskSubmissions, getEventTaskPollResults } from '@/shared/actions/events';
import { formatTimeAgo, dateHasPassed } from '@/shared/utils/dateTime';


@Options({
  name: 'EventsPage',
  components: {},
})
export default class EventsPage extends Vue {
  formatTimeAgo = formatTimeAgo;
  dateHasPassed = dateHasPassed;
  public pollData: SiteEventTaskPoll = {
    id: '',
    question: '',
    show_results: false,
    options: [],
  };

  public pollSubmission: SiteEventTaskSubmission | null = null;

  public get pollOptions(): SiteEventTaskPollOption[] {
    if (!this.pollData) return [];
    return this.pollData.options;
  }

  public get indexVotedFor(): number | null {
    if (!this.pollSubmission || !this.pollSubmission.submission_text) return null;
    return +this.pollSubmission.submission_text;
  }

  public get maxVoteCount(): number {
    const voteCounts = this.pollOptions.map((opt) => opt.vote_count || -1);
    return Math.max(...voteCounts);
  }

  public async fetchPollData() {
    const router = useRouter();
    const task = router.currentRoute.value.params.id;
    this.pollData = await getEventTaskPoll(task);
  }

  public async fetchUserTaskSubmission() {
    const router = useRouter();
    const task = router.currentRoute.value.params.id;
    const data = await getUserTaskSubmissions(task);
    this.pollSubmission = data[0];
  }

  public async fetchPollResults() {
    const router = useRouter();
    const task = router.currentRoute.value.params.id;
    const data = await getEventTaskPollResults(task);
    if (this.pollData) this.pollData.options = data;
  }

  public async makeSubmission(val: string) {
    if (this.pollSubmission) return;
    const t = this.pollOptions[+val].text;
    if (!confirm(`You are about to vote for: ${t}`)) {
      await toast.show('Action canceled', 'nonative', 'tertiary');
      return;
    }
    const router = useRouter();
    const task = router.currentRoute.value.params.id;
    const data = await submitToEventTask(task, { submission_text: val });
    this.pollSubmission = data;
    this.fetchPollResults();
  }

  public async created() {
    const document = useDocument();
    document.value?.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    this.fetchPollData();
    this.fetchUserTaskSubmission();
  }
}
</script>
<style lang="sass" scoped>
.clickable-item:hover
  opacity: 0.7
.title
  font-weight: bold
.option-image
  width: 150px
  height: 150px
  object-fit: cover
.option-container
  padding: 20px
  width: 25vw
  border: 1px solid
.purple-bg
  background-color: rgba(80, 9, 184, 0.2)
.q
  white-space: pre-wrap
.description
  white-space: pre-wrap
  background-color: #6D6C6C
  color: #FFF
  font-size: 12px
  .link-color
    color: #e3a7ff
</style>
